import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { Language, LanguageLowercase } from 'store/app/types'
import { IS_PROD_OR_BETA } from 'constants/envs'
import dutch from './dutch'
import french from './french'
import { getLowerCaseLanguage } from 'utils/app.ts'
import Cookies from 'js-cookie'

export const defaultNS = 'common'
export const nameSpaces = Object.keys(dutch)

export const resources = {
  nl: dutch,
  fr: french
} as const

/**
 * Define the default language
 */
const defaultLanguage = () => {
  const cookieLanguage = Cookies.get('language')?.toLowerCase()

  if (cookieLanguage === null) {
    const searchParams = new URLSearchParams(window.location.search)
    const urlLanguage = searchParams.get('lang')?.toLowerCase()

    if (!urlLanguage) {
      return getLowerCaseLanguage(Language.NL)
    }

    Cookies.set('language', urlLanguage, { expires: 365 })
    return urlLanguage
  }

  return cookieLanguage
}

i18n.use(initReactI18next).init({
  returnNull: false,
  debug: !IS_PROD_OR_BETA,
  lng: defaultLanguage(),
  fallbackLng: LanguageLowercase.NL,
  ns: nameSpaces,
  defaultNS,
  resources
})

export default i18n
