import { Language } from 'store/app/types.ts'
import { Address, Company } from 'types/general.ts'
import { DeliveryMode } from 'types/types.ts'
import { BillingContract } from 'types/contracts.ts'

export type ApiGetMyContactResponse = {
  contact: Contact
}

export type ApiGetContactsByEmailResponse = {
  contacts: Contact[]
}

export type Contact = {
  // Key
  sfId: string
  dbId?: string

  // Properties
  email: string
  firstName: string
  language: Language
  lastName: string
  phone: string
  status: ContactStatus
  userTester: boolean

  // Relations
  accounts?: Account[]
}

type Attestation = {
  ean: string
  fromDate: string
  toDate: string
}

type PaymentDetails = {
  directDebit?: boolean
  iban?: string
  mandateNumber?: string
}

export type Account = {
  // Keys
  sfId: string
  customerNumber: string

  // Foreign key
  contactSfId: string

  // Haugazel properties
  attestations?: Attestation[]

  // Mercator properties
  paymentDetails?: PaymentDetails

  // Salesforce properties
  ownerId?: string
  birthDate?: string
  company?: Company
  correspondenceAddress: Address
  deliveryMode: DeliveryMode
  firstName: string
  lastName: string
  missingEan: boolean
  name: string
  recordType: AccountRecordTypeIds
  type: AccountType
  producerId?: string
  phoneNumber: string

  // Relations
  billingContracts: BillingContract[]
  opportunities: Opportunity[]
}

// Only used for sales
export type Opportunity = {
  // Key
  sfId: string

  // Foreign key
  accountSfId: string

  // Properties
  address: Address
  agentId: string
  closeDate: string
  eventName: string
  extraAddressFlow: boolean
  forcedAmount: number
  ibanNumber: string
  partnerAccount: string
  paymentMode: PaymentMode
  pricePerMonthInclVat: number
  signChannel: SignChannel
  simulationType: SimulationType
  stage: OpportunityStage
}

export type Referral = {
  closeDate?: string // ISO Date
  firstName: string
  lastName: string
  pending: boolean
  reward?: string
  createdAt: string // ISO Data
  simulationSalesOffice?: string
}

// ENUMS

export enum AccountRecordTypeIds {
  CONSUMER = '0122X000000KWuCQAW',
  PARTNER = '0122X000000KWuMQAW',
  PRODUCER = '0122X000000KWuHQAW'
}

export enum AccountType {
  CUSTOMER = 'Customer',
  PROSPECT = 'Prospect'
}

export enum ContactStatus {
  COLD = 'Cold',
  CUSTOMER = 'Customer',
  HOT = 'Hot',
  SUPER_HOT = 'Super Hot',
  WARM = 'Warm'
}

export enum OpportunityStage {
  CLOSED_LOST = 'Closed Lost',
  CLOSED_WON = 'Closed Won',
  IN_PROGRESS = 'In Progress',
  NEEDS_ANALYSIS = 'Needs Analysis',
  NEGOTIATION = 'Negotiation',
  NEW = 'New',
  NO_EAN = 'No EAN',
  PARKED = 'Parked',
  PROPOSAL = 'Proposal',
  QUALIFICATION = 'Qualification',
  REGISTRATION_STARTED = 'Registration Started',
  TO_BE_CONFIRMED = 'To Be Confirmed'
}

export enum PaymentMode {
  DOMICILATION = 'Domiciliation',
  TRANSFER = 'Transfer'
}

export enum SignChannel {
  CONFIRMATION_MAIL = 'Confirmation Mail'
}

// Only EAV and Fixed Amount are being used in multiple addresses registration flow
export enum SimulationType {
  BOLT_GO = 'Bolt Go',
  CONTRACT_VOLUME = 'Contract Volume',
  EAV = 'EAV',
  FIXED_AMOUNT = 'Fixed Amount',
  PRICING_SIMULATION = 'Pricing Simulation'
}
