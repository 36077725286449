import { ReactElement, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { CurrentStepData, TerminateData, TerminateStepProps, TerminateSteps } from './types'
import { hasDigitalMeter, triggerGetMeterReadings } from 'utils/contracts'
import MeterDetails from 'features/contracts/terminate/steps/MeterDetails/MeterDetails.tsx'
import Overview from 'features/contracts/terminate/steps/Overview/Overview.tsx'
import { useTranslation } from 'react-i18next'
import ChurnReason from 'features/contracts/terminate/steps/ChurnReason/ChurnReason.tsx'
import { TERMINATE_FLOW } from './constants'
import styles from '../Contracts.module.scss'
import TerminateSideCard from './components/TerminateSideCard/TerminateSideCard'
import SeoTags from 'components/SeoTags/SeoTags'
import { useStoreSelector } from 'hooks/store'
import ContractsLayout from 'features/contracts/layout/ContractsLayout.tsx'
import { ContractFlows } from 'features/contracts/layout/types.ts'
import ReferralStep from 'features/contracts/referral/ReferralStep.tsx'
import Done from 'features/contracts/done/Done.tsx'
import { routes } from 'types/routes.ts'
import { useNavigate } from 'react-router'
import { ContractStatus } from 'types/contracts.ts'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import ConditionalCard from 'components/ConditionalCard/ConditionalCard'
import useWindowSize from 'hooks/useWindowSize'

const Terminate = () => {
  // REDUX STORE
  const { billingContracts, selected } = useStoreSelector((store) => store.contact)
  const { meterReadings } = useStoreSelector((store) => store.contracts)

  // Router
  const navigate = useNavigate()

  // Contracts
  const { electricity, gas } = selectCurrentContracts({ billingContracts, selected })?.serviceContracts

  // i18n
  const { t } = useTranslation('contracts')

  // Window size
  const { isTablet } = useWindowSize()

  // Local state
  const [currentStep, setCurrentStep] = useState<TerminateSteps>(TerminateSteps.CHURN)
  const [terminateData, setTerminateData] = useState<TerminateData>({
    [TerminateSteps.CHURN]: undefined,
    [TerminateSteps.METER_DETAILS]: {
      date: dayjs().toISOString(),
      digitalMeter: hasDigitalMeter(electricity),
      docUpload: null,
      indexes: {}
    },
    [TerminateSteps.REFERRAL]: undefined
  })

  /**
   * Redirect to overview if contract is already terminated
   */
  useEffect(() => {
    if (electricity.detail.status === ContractStatus.TERMINATED) {
      navigate(routes.CONTRACT_MY_CONTRACT)
    }
  }, [electricity.detail.status, navigate])

  /**
   * Fetch meter readings if none available
   */
  useEffect(() => {
    if (!meterReadings.data) triggerGetMeterReadings(electricity, gas)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Goes to the next step & saves the data to the local state if given
   * @param {CurrentStepData} currentStepData
   * @param {TerminateSteps} nextStep
   */
  const setNextStep = (currentStepData: CurrentStepData, nextStep: TerminateSteps) => {
    setTerminateData({
      ...terminateData,
      [currentStep]: currentStepData
    })
    setCurrentStep(nextStep)
  }

  /**
   * Returns the component for the current step
   *
   * @returns {ReactElement}
   */
  const getCurrentStepComponent = (): ReactElement | null => {
    const stepProps = {
      setNextStep,
      terminateData,
      setCurrentStep
    } satisfies TerminateStepProps

    switch (currentStep) {
      case TerminateSteps.CHURN:
        return <ChurnReason {...stepProps} />

      case TerminateSteps.METER_DETAILS:
        return <MeterDetails {...stepProps} />

      case TerminateSteps.REFERRAL:
        return (
          <ReferralStep
            defaultValues={terminateData[TerminateSteps.REFERRAL]}
            setCurrentStep={(step) => setCurrentStep((step as TerminateSteps) || TerminateSteps.OVERVIEW)}
            setNextStep={(data) => setNextStep(data, TerminateSteps.OVERVIEW)}
            isTerminate
          />
        )

      case TerminateSteps.OVERVIEW:
        return <Overview {...stepProps} />

      default:
        return null
    }
  }

  /**
   * Scroll to top of page every time currentStep changes
   */
  useEffect(() => {
    window.scrollTo({ top: 0 })

    return () => {
      window.scrollTo({ top: 0 })
    }
  }, [currentStep])

  return (
    <>
      <SeoTags title={t('seo.terminate')} />

      <ContractsLayout
        flow={ContractFlows.TERMINATE}
        label={t('header.labels.terminate')}
        steps={TERMINATE_FLOW}
        {...{ currentStep, setCurrentStep }}
      >
        {currentStep === TerminateSteps.DONE ? (
          <Done />
        ) : (
          <>
            <ConditionalCard asCard={!isTablet} className={styles.card}>
              {getCurrentStepComponent()}
            </ConditionalCard>
            <TerminateSideCard />
          </>
        )}
      </ContractsLayout>
    </>
  )
}

export default Terminate
