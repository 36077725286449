import { Suspense } from 'react'
import GoogleMapReact from 'google-map-react'
import styles from './mapStyles.json'
import { GMAP_KEY } from 'constants/envs'
import { ProducerMapProps } from './types'
import Marker from './Marker/Marker'
import { energyToIconName } from 'utils/mapping.ts'
import { getAverage } from 'utils/number.ts'

const ProducerMap = ({ producerLocation, userLocation, producerEnergyType, producerLocations }: ProducerMapProps) => {
  // Constants
  const isProducerProfile = !!producerLocations
  const centerCoord = {
    lat: getAverage(
      isProducerProfile
        ? producerLocations?.map((loc) => loc.lat)
        : userLocation
          ? [producerLocation.lat, userLocation.lat]
          : [producerLocation.lat]
    ),
    lng: getAverage(
      isProducerProfile
        ? producerLocations?.map((loc) => loc.lng)
        : userLocation
          ? [producerLocation.lng, userLocation.lng]
          : [producerLocation.lng]
    )
  }

  return (
    <Suspense fallback={<div className="card" />}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GMAP_KEY || '' }}
        center={centerCoord}
        defaultZoom={8}
        yesIWantToUseGoogleMapApiInternals
        options={{
          styles,
          scrollwheel: false,
          disableDoubleClickZoom: true,
          draggable: true,
          zoomControl: true,
          minZoom: 4,
          maxZoom: 18,
          fullscreenControl: false,
          gestureHandling: 'cooperative'
        }}
      >
        {isProducerProfile &&
          producerLocations?.map((loc, idx) => (
            <Marker key={idx} icon={loc.energyType ? energyToIconName(loc.energyType) : 'electricity'} lat={loc.lat} lng={loc.lng} />
          ))}
        {!isProducerProfile && producerLocation && (
          <Marker
            key="producer"
            icon={producerEnergyType ? energyToIconName(producerEnergyType) : 'electricity'}
            lat={producerLocation.lat}
            lng={producerLocation.lng}
          />
        )}
        {!isProducerProfile && userLocation && <Marker key="user" icon="home" lat={userLocation.lat} lng={userLocation.lng} />}
      </GoogleMapReact>
    </Suspense>
  )
}

export default ProducerMap
