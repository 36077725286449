import { PhoneField, validatePhoneNumber } from '@boltenergy-be/design-system'
import { useTranslation } from 'react-i18next'
import { PhoneProps } from './types.ts'

const Phone = ({ hookFormIdentifier, hookForm, ...phoneProps }: PhoneProps) => {
  // i18n
  const { t } = useTranslation('validation')

  // Constants
  const key = hookFormIdentifier
  const { register, setValue, clearErrors, watch, formState, trigger } = hookForm
  const watchPhone = watch(key)

  return (
    <PhoneField
      {...phoneProps}
      {...register(key, {
        required: t('required'),
        validate: (value) => validatePhoneNumber(value || '') || t('invalid.phone')
      })}
      value={watchPhone}
      error={formState?.errors?.[key]?.message as string | undefined}
      onChange={(value) => {
        setValue(key, value, { shouldDirty: true })
        clearErrors(key)
      }}
      onBlur={() => trigger(key)}
    />
  )
}

export default Phone
