import styles from './ConsumptionSection.module.scss'
import { Banner, Button } from '@boltenergy-be/design-system'
import { AccessRightsKeys, EnergyType, Granularity } from 'types/contracts.ts'
import { FormProvider, useForm } from 'react-hook-form'
import ConsumptionNavigation from 'components/Charts/consumption-navigation/ConsumptionNavigation.tsx'
import ConsumptionChart from 'components/Charts/consumption/ConsumptionChart.tsx'
import { ConsumptionNavigationForm, NavigationItemType } from 'components/Charts/consumption-navigation/types.ts'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { Response } from 'types/request.ts'
import { ELProduct } from 'types/products.ts'
import { routes } from 'types/routes.ts'
import { useStoreSelector } from 'hooks/store.ts'
import useWindowSize from 'hooks/useWindowSize.tsx'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactElement, useEffect, useMemo } from 'react'
import { determineAccessRights, hasSmartMeter, isActiveContract } from 'utils/contracts.ts'
import { memoizedGenerateGranularityNavigationItems } from './utils.ts'
import { useGetPreviousBillingCyclePeriodsQuery, useGetVolumesQuery } from 'store/queries/bolt-api/contracts'
import { memoizedGenerateGetVolumesQuery } from 'pages/App/consumption/utils.ts'
import { ISO_DATETIME_FORMAT } from 'constants/constants.ts'
import { TransfoErrorCodes } from 'types/billShock.ts'
import { ContractResponseCodes } from 'types/errorCodes.ts'
import { ConsumptionSectionProps, GranularityOptions } from './types.ts'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import { ConsumptionEvents, ConsumptionTrackingParams } from 'types/tracking.ts'
import mixpanel from 'mixpanel-browser'
import EmptyState from 'components/ReturnLater/EmptyState.tsx'
import { checkHasFixedProduct } from 'utils/products.ts'
import { selectCurrentContracts } from 'store/contact/selectors.ts'
import Link from 'components/Link/Link.tsx'
import classNames from 'classnames'

const ConsumptionSection = ({ energyType, compact, setHide }: ConsumptionSectionProps) => {
  // Redux
  const { isProducer, selected, billingContracts } = useStoreSelector((store) => store.contact)

  // Contracts
  const billingContract = selectCurrentContracts({ selected, billingContracts })
  const { electricity, gas } = billingContract.serviceContracts
  const currentContract = gas && energyType === EnergyType.GAS ? gas : electricity

  // Access rights
  const accessRights = determineAccessRights(electricity)

  // Window size
  const { isTablet } = useWindowSize()

  // i18n
  const { t } = useTranslation(['consumption', 'common'])

  // React router
  const navigate = useNavigate()
  const { search } = useLocation()
  const query = useMemo<URLSearchParams>(() => new URLSearchParams(search), [search])

  // Contracts
  const isMonthlyBilling = !!(electricity.detail.monthlyBilling && (gas ? gas?.detail?.monthlyBilling : true))
  const granularities: GranularityOptions = memoizedGenerateGranularityNavigationItems(currentContract)
  const startAsBillingCycle = !isMonthlyBilling && !!query.get('cycle')

  // React hook form
  const hookForm = useForm<ConsumptionNavigationForm>({
    defaultValues: {
      view: startAsBillingCycle ? NavigationItemType.CYCLE : Granularity.MONTH,
      period: startAsBillingCycle
        ? (query.get('cycle') as string)
        : granularities[Granularity.MONTH].periods?.find((period) => period.defaultSelected)?.value
    }
  })
  const watchView = hookForm.watch('view')
  const watchPeriod = hookForm.watch('period')

  // Reset period when there is no matching period for the selected granularity view
  useEffect(() => {
    const currentGranularity = watchView !== NavigationItemType.CYCLE ? granularities[watchView] : undefined
    if (!!currentGranularity?.periods && !currentGranularity.periods.some((period) => period.value === watchPeriod)) {
      const defaultPeriod =
        currentGranularity.periods.find((period) => period.defaultSelected)?.value || currentGranularity.periods[0].value
      hookForm.setValue('period', defaultPeriod)
    }
  }, [granularities, hookForm, watchPeriod, watchView])

  // Redux Queries
  const { data: previousBillingCyclesPeriods, isLoading: isLoadingPreviousBillingCyclesPeriods } = useGetPreviousBillingCyclePeriodsQuery(
    billingContract!.contractNumber,
    { skip: !accessRights.consumption.showContent || !currentContract!.contractNumber }
  )
  const volumesQuery = memoizedGenerateGetVolumesQuery({
    currentView: watchView,
    currentPeriod: watchPeriod,
    billingCyclePeriods: previousBillingCyclesPeriods
  })
  const {
    data,
    isLoading: isVolumesLoading,
    isFetching: isFetchingVolumes,
    isError,
    error
  } = useGetVolumesQuery(
    {
      serviceContractId: currentContract!.contractNumber,
      granularity: volumesQuery.granularity,
      from: volumesQuery.from.format(ISO_DATETIME_FORMAT),
      until: volumesQuery.until.format(ISO_DATETIME_FORMAT)
    },
    {
      skip:
        !accessRights.consumption.showContent ||
        !currentContract?.contractNumber ||
        (!previousBillingCyclesPeriods && watchView === NavigationItemType.CYCLE)
    }
  )

  // useMemo
  const billingCycles = undefined
  // TODO: temporary disabled
  /*useMemo<{ [key: string]: NavigationItem } | undefined>(
    () => (!isMonthlyBilling ? generatePreviousBillingCycleNavigationItems(activeProductType, previousBillingCyclesPeriods) : undefined),
    [activeProductType, isMonthlyBilling, previousBillingCyclesPeriods]
  )*/

  // Constants
  const hasInjection = !!data?.volumes?.some((entry) => (entry.injection?.billed || entry.injection?.unbilled || 0) !== 0)
  const defaultGranularity = granularities[Granularity.MONTH]
  const selectedNavigationItem =
    watchView !== NavigationItemType.CYCLE
      ? granularities?.[watchView] || defaultGranularity
      : billingCycles
        ? billingCycles[watchPeriod]
        : defaultGranularity
  const showEstimatedUsage = selectedNavigationItem.type === NavigationItemType.GRANULARITY
  const dataTranslation = {
    dataTranslation: t(`yourConsumption.data.${isProducer ? 'producer' : 'consumer'}`),
    interpolation: { escapeValue: false }
  }

  // When the card contains an error, hide it on the parent
  useEffect(() => {
    if (error && typeof setHide === 'function') {
      setHide(true)
    }
  }, [error, setHide])

  /**
   * Track the consumption navigation item viewed
   */
  useEffect(() => {
    mixpanel.track(ConsumptionEvents.CONSUMPTION_NAVIGATION_ITEM_VIEWED, {
      [ConsumptionTrackingParams.VIEW]: watchView,
      [ConsumptionTrackingParams.FUEL_TYPE]: energyType
    })
  }, [watchView, energyType])

  /**
   * Returns the correct consumption error for the given code
   *
   * @param {TransfoErrorCodes | null} code
   * @returns {ReactElement}
   */
  const getConsumptionError = (code?: TransfoErrorCodes | ContractResponseCodes | string): ReactElement => {
    // Own API errors
    if (code === ContractResponseCodes.NO_SMR3_VOLUMES_AVAILABLE_YET) {
      return <Banner type="informative">{t('yourConsumption.info.noUsage.SMR3.tooRecent', dataTranslation)}</Banner>
    }

    if ([ContractResponseCodes.NO_VOLUMES, ContractResponseCodes.NO_USABLE_VOLUMES].includes(code as ContractResponseCodes)) {
      const isDigitalMeter = hasSmartMeter(currentContract)

      return (
        <Banner
          type="informative"
          title={
            electricity.detail.dynamicTariff
              ? t('yourConsumption.info.noUsage.SMR3.unavailable', dataTranslation)
              : t(`yourConsumption.info.noUsage.nonSMR3.${isDigitalMeter ? 'digital' : 'analog'}`, dataTranslation)
          }
        >
          {accessRights[AccessRightsKeys.METER_READINGS].canAccess && !isDigitalMeter && !electricity.detail.dynamicTariff && (
            <Link representation="button" variant="secondary" href={routes.CONSUMPTION_METER_READINGS}>
              {t('yourConsumption.info.noUsage.nonSMR3.link', dataTranslation)}
            </Link>
          )}
        </Banner>
      )
    }

    // API & Transfo errors
    if (
      [
        TransfoErrorCodes.E304_CONTRACT_TOO_YOUNG,
        TransfoErrorCodes.E444_MISSING_DECENTRALIZED_PRODUCTION,
        TransfoErrorCodes.E454_MISSING_SERVICE_DELIVERIES,
        TransfoErrorCodes.E456_DELIVERY_OUT_OF_SCOPE
      ].includes(code as TransfoErrorCodes)
    ) {
      return (
        <Banner type="informative">
          {t(
            `transfoErrors.${
              code as
                | TransfoErrorCodes.E304_CONTRACT_TOO_YOUNG
                | TransfoErrorCodes.E444_MISSING_DECENTRALIZED_PRODUCTION
                | TransfoErrorCodes.E454_MISSING_SERVICE_DELIVERIES
                | TransfoErrorCodes.E456_DELIVERY_OUT_OF_SCOPE
            }`,
            {
              ns: 'common'
            }
          )}
        </Banner>
      )
    }

    return <div className={styles.error}>{t('errorTryLater', { ns: 'common' })}</div>
  }

  return (
    <section className={styles['consumption-section']}>
      {!accessRights.consumption.showContent ? (
        <>
          {/* FILTERING + METER READINGS */}
          {!compact && (
            <header className={classNames(styles.header, styles['empty-state-header'])}>
              <FormProvider {...hookForm}>
                <ConsumptionNavigation
                  disabledFilters
                  isLoading={isFetchingVolumes}
                  billingCycles={billingCycles || {}}
                  {...{ granularities, energyType }}
                />
              </FormProvider>
            </header>
          )}

          {isActiveContract(currentContract) ? (
            <EmptyState description={t('yourConsumption.returnLater')} />
          ) : (
            <EmptyState
              title={t('common:emptyState.noData.title', dataTranslation)}
              icon="cross"
              description={t('common:emptyState.noData.description')}
            />
          )}
        </>
      ) : (
        <div className={styles['volumes-wrapper']}>
          {!isVolumesLoading && !isLoadingPreviousBillingCyclesPeriods && !!selectedNavigationItem ? (
            <>
              {/* FILTERING + METER READINGS */}
              {!compact && (
                <header className={styles.header}>
                  <FormProvider {...hookForm}>
                    <ConsumptionNavigation
                      isLoading={isFetchingVolumes}
                      billingCycles={billingCycles || {}}
                      {...{ granularities, energyType }}
                    />
                  </FormProvider>
                </header>
              )}

              {/* CONSUMPTION CHART */}
              {!isError && !error && data ? (
                <>
                  <ConsumptionChart
                    {...{
                      isFetchingVolumes,
                      granularity: watchView === NavigationItemType.CYCLE ? Granularity.MONTH : watchView,
                      hasInjection,
                      isTablet,
                      showEstimatedUsage,
                      compact
                    }}
                    data={!isFetchingVolumes ? data.volumes : []}
                    totals={{
                      totalConsumption: data.totalConsumption,
                      totalInjection: data.totalInjection
                    }}
                    activeEnergyType={currentContract.detail.energyType}
                    hidePriceHistory={compact || checkHasFixedProduct()}
                  />

                  {!isFetchingVolumes && data.nonSmr3VolumesAvailable && currentContract.detail.dynamicTariff && (
                    <Banner type="informative">{t('yourConsumption.info.noUsage.SMR3.partlyUnavailable')}</Banner>
                  )}
                </>
              ) : (
                getConsumptionError(typeof error === 'string' ? error : ((error as FetchBaseQueryError)?.data as Response)?.message)
              )}

              {!isFetchingVolumes && currentContract.detail?.productCode !== ELProduct.Go && watchView === NavigationItemType.CYCLE && (
                <Button
                  className={styles['billing-cycle-button']}
                  onClick={() =>
                    navigate({
                      pathname: routes.BILLING_CYCLES,
                      search: `cycle=${watchPeriod}`
                    })
                  }
                >
                  {t('chart.back')}
                </Button>
              )}
            </>
          ) : (
            <LoadingSkeleton className="mb-auto mt-none">
              {!compact && <LoadingSkeleton.Rectangle height={98} />}
              <div className={styles['totals-loader']}>
                <LoadingSkeleton.Rectangle height={60} />
                <LoadingSkeleton.Rectangle height={60} />
              </div>
              <LoadingSkeleton.Rectangle aspectRatio="3 / 1" />
            </LoadingSkeleton>
          )}
        </div>
      )}
    </section>
  )
}

export default ConsumptionSection
