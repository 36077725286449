import { AccountingEntity, CustomerAccountingDocument, CustomerPayment, DocumentType } from 'types/types.ts'
import { filteredInvoicesProps, FilterOutDirectDebitBounceProps, InvoiceTableTabs } from 'pages/App/billing/invoices/table-data/types.ts'
import dayjs from 'dayjs'
import { memoize } from 'proxy-memoize'

/**
 * Returns filtered documents for a given route.
 * @param {filteredInvoicesProps} props
 * @returns {CustomerAccountingDocument[]}
 */
export const filterDocumentsBasedOnTab = ({ tab, documents }: filteredInvoicesProps): CustomerAccountingDocument[] => {
  if (!documents?.length) return []

  const isAlsoSupplier = documents?.some((doc) => doc.accountingEntity === AccountingEntity.SUPPLIER)

  switch (tab) {
    case InvoiceTableTabs.SUPER_USER_CUSTOMER:
      return isAlsoSupplier ? documents.filter((document) => document.accountingEntity === AccountingEntity.CUSTOMER) : documents
    case InvoiceTableTabs.SUPER_USER_SUPPLIER:
      return documents.filter((document) => document.accountingEntity === AccountingEntity.SUPPLIER)
    case InvoiceTableTabs.ALL_INVOICES:
      return documents.filter((document) => document.documentType === DocumentType.INVOICE)
    case InvoiceTableTabs.ALL_TRANSACTIONS:
      return documents.filter((document) => document.documentType === DocumentType.PAYMENT)
    case InvoiceTableTabs.OUTSTANDING_INVOICES:
      return documents.filter((document) => !document.paid && document.accountingEntity === AccountingEntity.CUSTOMER)

    default:
      return documents
  }
}

/**
 * Group the payments within 5 days of each other
 * @param {CustomerPayment[]} payments
 * @returns {CustomerPayment[][]}
 */
const groupPaymentsWithin5Days = (payments: CustomerPayment[]): CustomerPayment[][] => {
  if (!payments.length) return []

  // Sort payments by date
  const sortedPayments = payments.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)))
  const groupedPayments: CustomerPayment[][] = []
  let currentGroup: CustomerPayment[] = [sortedPayments[0]]

  for (let i = 1; i < sortedPayments.length; i++) {
    const currentPayment = sortedPayments[i]
    const lastPaymentInGroup = currentGroup[currentGroup.length - 1]
    const dateDifference = dayjs(currentPayment.date).diff(dayjs(lastPaymentInGroup.date), 'day')

    // Check if the current payment is within 5 days of the last payment in the current group
    if (dateDifference <= 5) {
      currentGroup.push(currentPayment)
    } else {
      groupedPayments.push(currentGroup)
      currentGroup = [currentPayment]
    }
  }

  // Push the last group
  groupedPayments.push(currentGroup)

  return groupedPayments
}

/**
 * Add the pair of payments to the set
 * @param {CustomerPayment[]} pair - The pair of payments
 * @param {Set<string>} set - The set to add the ids to
 */
const addPairToSet = (pair: [CustomerPayment, CustomerPayment], set: Set<string>) => {
  const [firstPayment, secondPayment] = pair
  set.add(firstPayment.id)

  // If the id isn't matching, add the second id as well (could happen that the ids are equal)
  if (!set.has(secondPayment.id)) {
    set.add(secondPayment.id)
  }
}

/**
 * Determine the payment ids to filter out
 * @param {CustomerPayment[][]} groupedPaymentsWithMultiplePayments
 * @returns {Set<string>}
 */
const determinePaymentIdsToFilterOut = (groupedPaymentsWithMultiplePayments: CustomerPayment[][]): Set<string> => {
  // Set to keep track of document ids to be filtered out
  const toFilterPaymentIds = new Set<string>()

  // Loop over the groups of payments and find the matching pairs
  for (const paymentGroup of groupedPaymentsWithMultiplePayments) {
    // If the group has 2 payments, check if they compensate each other and add them to the filter list
    if (paymentGroup.length === 2) {
      const [firstPayment, secondPayment] = paymentGroup

      // Compensation only occurs if the sum of the amounts with opposite signs is 0
      if (firstPayment.amount + secondPayment.amount === 0) {
        addPairToSet([firstPayment, secondPayment], toFilterPaymentIds)
      }
    } else {
      const groupedByAbsAmount = new Map<number, CustomerPayment[]>()

      // Group the payments by the absolute amount
      for (const payment of paymentGroup) {
        const absAmount = Math.abs(payment.amount)
        if (!groupedByAbsAmount.has(absAmount)) {
          groupedByAbsAmount.set(absAmount, [])
        }
        groupedByAbsAmount.get(absAmount)!.push(payment)
      }

      // Loop over the grouped payments and find the matching pairs
      for (const payments of groupedByAbsAmount.values()) {
        if (payments.length === 2) {
          const [firstPayment, secondPayment] = payments
          if (firstPayment.amount + secondPayment.amount === 0) {
            addPairToSet([firstPayment, secondPayment], toFilterPaymentIds)
          }
        } else {
          const firstNegativePayment = payments.find(({ amount }) => amount < 0)
          const firstPositivePayment = payments.find(({ amount }) => amount > 0)
          if (firstNegativePayment && firstPositivePayment) {
            addPairToSet([firstNegativePayment, firstPositivePayment], toFilterPaymentIds)
          }
        }
      }
    }
  }

  return toFilterPaymentIds
}

/**
 * Filter out the direct debit bounce documents
 * @param {FilterOutDirectDebitBounceProps} props
 * @returns {(CustomerAccountingDocument)[]} - The filtered list of accounting documents
 */
const filterOutDirectDebitBounce = ({ documents, tab }: FilterOutDirectDebitBounceProps): CustomerAccountingDocument[] => {
  // Define the routes that require filtering
  const routesToFilter = [InvoiceTableTabs.ALL_INVOICES, InvoiceTableTabs.ALL_TRANSACTIONS, InvoiceTableTabs.OUTSTANDING_INVOICES]

  // If direct debit is not used or the route is not in the routesToFilter, return the original documents
  if (!routesToFilter.includes(tab)) {
    return documents
  }

  // Only keep the payment documents
  const payments = documents.filter((document) => document.documentType === DocumentType.PAYMENT)

  // Group the payments within 5 days
  const groupedPaymentsWithMultiplePayments = groupPaymentsWithin5Days(payments).filter((group) => group.length > 1)

  // Determine the payment ids to filter out
  const toFilterPaymentIds = determinePaymentIdsToFilterOut(groupedPaymentsWithMultiplePayments)

  // If no documents to filter out, return the original documents
  if (toFilterPaymentIds.size === 0) {
    return documents
  }

  // Return the filtered documents
  return documents.filter((document) => !toFilterPaymentIds.has(document.id))
}

/**
 * Memoized version of filterOutDirectDebitBounce
 */
export const memoizedFilterOutDirectDebitBounce = memoize<FilterOutDirectDebitBounceProps, CustomerAccountingDocument[]>(
  filterOutDirectDebitBounce
)
