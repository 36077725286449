import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { MIN_AGE } from 'features/contracts/add/constants.ts'
import { isUnderaged, isValidDate } from 'utils/addContract.ts'
import { DateFieldProps } from 'components/FormFields/DateFormField/types.ts'
import styles from 'components/FormFields/DateFormField/DateFormField.module.scss'
import { BirthDate } from 'features/contracts/add/types.ts'
import { InlineMessage, Select } from '@boltenergy-be/design-system'

const DateFormField = ({ checkDateOfBirth, label, isFuture, isRequired, isDisabled }: DateFieldProps) => {
  // i18n
  const { t } = useTranslation(['contracts', 'validation', 'common'])

  // React hook form context
  const {
    watch,
    setError,
    setValue,
    clearErrors,
    register,
    formState: { errors }
  } = useFormContext<{ dateOfBirth: BirthDate }>()

  // Constants
  const watchDate = watch('dateOfBirth')

  /**
   * Validates the date
   */
  const validateDateOfBirth = () => {
    clearErrors('dateOfBirth')

    const { day, month, year } = watchDate

    if (day !== '' && month !== '' && year !== '') {
      const dateIsValid = isValidDate(day, month, year)

      // Check date if birth is valid
      if (dateIsValid) {
        // Check if person is old enough
        if (checkDateOfBirth && isUnderaged(day, month, year)) {
          setError('dateOfBirth', {
            type: 'notOldEnough',
            message: t('fulfillment:personalDataInvalidAge', 'Je moet min. 18 jaar zijn om een contract te kunnen afsluiten.')
          })
        }
      } else {
        setError('dateOfBirth', { type: 'invalid', message: t('invalidDate', 'Dit is geen geldige datum') })
      }
    }
  }

  return (
    <div className={styles.birthday}>
      <label htmlFor="dateOfBirth">{label}</label>
      <div className={styles['birthday-select']}>
        <Select
          id="day"
          label=""
          {...register('dateOfBirth.day', {
            required: isRequired ? t('validation:required') : false
          })}
          disabled={isDisabled}
          onChange={(e) => {
            setValue('dateOfBirth.day', e.target.value, { shouldDirty: true })
            validateDateOfBirth()
          }}
          error={errors?.dateOfBirth?.day?.message}
        >
          <option value="" disabled>
            {t('add.steps.customerData.fields.birthDate.day')}
          </option>
          {Array.from({ length: 31 }).map((_, index) => (
            <option key={index} value={index + 1}>
              {(index + 1).toString().padStart(2, '0')}
            </option>
          ))}
        </Select>

        <Select
          id="month"
          label=""
          {...register('dateOfBirth.month', {
            required: isRequired ? t('validation:required') : false
          })}
          disabled={isDisabled}
          onChange={(e) => {
            setValue('dateOfBirth.month', e.target.value, { shouldDirty: true })
            validateDateOfBirth()
          }}
          error={errors?.dateOfBirth?.month?.message}
        >
          <option value="" disabled>
            {t('add.steps.customerData.fields.birthDate.month')}
          </option>
          {Array.from({ length: 12 }).map((_, index) => (
            <option key={index} value={index + 1}>
              {(index + 1).toString().padStart(2, '0')}
            </option>
          ))}
        </Select>

        <Select
          id="year"
          label=""
          {...register('dateOfBirth.year', {
            required: isRequired ? t('validation:required') : false
          })}
          disabled={isDisabled}
          onChange={(e) => {
            setValue('dateOfBirth.year', e.target.value, { shouldDirty: true })
            validateDateOfBirth()
          }}
          error={errors?.dateOfBirth?.year?.message}
        >
          <option value="" disabled>
            {t('add.steps.customerData.fields.birthDate.year')}
          </option>
          {!isFuture
            ? Array.from({ length: 100 - MIN_AGE })
                .reverse()
                .map((_, index) => {
                  const year = new Date().getFullYear() - MIN_AGE - index

                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })
            : Array.from({ length: 25 }).map((_, index) => {
                const year = new Date().getFullYear() + index

                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                )
              })}
        </Select>
      </div>
      {errors.dateOfBirth &&
        (errors.dateOfBirth.day?.type === 'required' ||
          errors.dateOfBirth.month?.type === 'required' ||
          errors.dateOfBirth.year?.type === 'required') && <InlineMessage type="negative">{t('validation:required')}</InlineMessage>}

      {errors && (errors.dateOfBirth?.type?.toString() === 'notOldEnough' || errors.dateOfBirth?.type?.toString() === 'invalid') && (
        <InlineMessage type="negative">{errors.dateOfBirth?.message}</InlineMessage>
      )}
    </div>
  )
}

export default DateFormField
