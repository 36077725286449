const {
  VITE_API_URL,
  VITE_CMS_URL,
  VITE_FACEBOOK_APP_ID,
  VITE_GMAP_KEY,
  VITE_GMAP_STATIC_KEY,
  VITE_MIXPANEL_TOKEN,
  VITE_NODE_ENV,
  VITE_SYSTEM_DOWN,
  VITE_WEBSITE_URL,
  VITE_CLOUDINARY_CLOUD_NAME,
  VITE_DEBUG_ROUTER,
  VITE_BUGSNAG_API_KEY,
  VITE_TESTING
} = import.meta.env

export const API_URL = VITE_API_URL ?? ''
export const NODE_ENV = VITE_NODE_ENV || 'local'
export const GMAP_KEY = VITE_GMAP_KEY
export const CMS_URL = VITE_CMS_URL
export const GMAP_STATIC_KEY = VITE_GMAP_STATIC_KEY
export const WEBSITE_URL = VITE_WEBSITE_URL
export const MIXPANEL_TOKEN = VITE_MIXPANEL_TOKEN as string
export const FACEBOOK_APP_ID = VITE_FACEBOOK_APP_ID as string
export const SYSTEM_DOWN = VITE_SYSTEM_DOWN === 'true'
export const CLOUDINARY_NAME = VITE_CLOUDINARY_CLOUD_NAME
export const DEBUGGING_ROUTER = VITE_DEBUG_ROUTER === 'true'
export const BUGSNAG_API_KEY = VITE_BUGSNAG_API_KEY
export const IS_TESTING = VITE_TESTING === 'true'

// Environment checks
export const IS_PRODUCTION = NODE_ENV === 'production'
export const IS_BETA = NODE_ENV === 'beta'
export const IS_STAGING = NODE_ENV === 'staging'
export const IS_PREVIEW = NODE_ENV === 'preview'
export const IS_LOCAL = NODE_ENV === 'local'
export const IS_PROD_OR_BETA = IS_BETA || IS_PRODUCTION
export const IS_STAGING_OR_PREVIEW = IS_STAGING || IS_PREVIEW
