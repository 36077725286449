import styles from './EnvironmentRibbon.module.scss'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Icon } from '@boltenergy-be/design-system'
import { ENVIRONMENT_RIBBON_CONFIG } from './constants.ts'
import classNames from 'classnames'
import dayjs from 'dayjs'
import Snow from 'react-canvas-confetti/dist/presets/snow'
import Fireworks from 'react-canvas-confetti/dist/presets/fireworks'
import { TConductorInstance } from 'react-canvas-confetti/dist/types'
import { useStoreSelector } from 'hooks/store.ts'
import { UserTypes } from 'store/auth/types.ts'
import { IS_STAGING, IS_TESTING } from 'constants/envs.ts'
import { isAuthenticated } from 'utils/localStorageService.ts'

const EnvironmentRibbon = () => {
  // Redux
  const { userType } = useStoreSelector((store) => store.auth)

  // Ref
  const controller = useRef<TConductorInstance>()

  const getEnvironment = (): keyof typeof ENVIRONMENT_RIBBON_CONFIG | null => {
    if (IS_TESTING) return 'testing'
    if (IS_STAGING) return 'staging'
    if (isAuthenticated().superuser && userType === UserTypes.SUPER_USER) return 'superUser'
    return null
  }

  // Constants
  const env = getEnvironment()
  const inbetweenChristmasNewYear = dayjs().isBetween(dayjs('2024-12-7'), dayjs('2024-12-31'))
  const isBeginningOfJanuary = dayjs().isBetween(dayjs('2025-01-01'), dayjs('2025-01-10'))
  const isHoliday = env === 'superUser' && (inbetweenChristmasNewYear || isBeginningOfJanuary)

  // Local state
  const [animate, setAnimate] = useState<boolean>(false)

  /**
   * Toggle the animation
   */
  const toggleAnimate = () => {
    setAnimate(!animate)
  }

  // Animation
  useEffect(() => {
    if (animate) {
      if (inbetweenChristmasNewYear) controller?.current?.run({ speed: 10 })
      else controller?.current?.shoot()
    } else {
      controller?.current?.stop()
    }

    return () => controller?.current?.stop()
  }, [animate, inbetweenChristmasNewYear])

  if (!env) return null

  return (
    <>
      <div
        id="ribbon"
        className={classNames(styles.ribbon, styles[ENVIRONMENT_RIBBON_CONFIG[env].color], {
          [styles.xmas]: inbetweenChristmasNewYear,
          [styles.animate]: animate
        })}
      >
        <div className={classNames(styles['ribbon-text'], { [styles.animate]: animate })}>
          {Array.from({ length: 30 }).map((_, idx) => (
            <Fragment key={idx}>
              <span>{ENVIRONMENT_RIBBON_CONFIG[env].text}</span>
              <Icon name={isBeginningOfJanuary ? 'star' : inbetweenChristmasNewYear ? 'christmasTree' : 'electricityOutline'} size={16} />

              {env && env !== 'superUser' && userType === UserTypes.SUPER_USER && isAuthenticated().superuser && (
                <>
                  <span>{ENVIRONMENT_RIBBON_CONFIG.superUser.text}</span>
                  <Icon name="electricityOutline" size={16} />
                </>
              )}

              {inbetweenChristmasNewYear && (
                <>
                  <button onClick={toggleAnimate}>Happy holidays from P&S</button>
                  <Icon name="christmasTree" size={16} />
                </>
              )}

              {isBeginningOfJanuary && (
                <>
                  <button onClick={toggleAnimate}>Happy new year from P&S</button>
                  <Icon name="star" size={16} />
                </>
              )}
            </Fragment>
          ))}
        </div>
      </div>

      {/* non-absolute div to push the content */}
      <div className={styles.push} />

      {/* :D */}
      {isHoliday && (
        <div className={styles.confetti}>
          {inbetweenChristmasNewYear ? (
            <Snow
              onInit={({ conductor }) => (controller.current = conductor)}
              className={styles.canvas}
              decorateOptions={(options) => ({
                ...options,
                particleCount: 100
              })}
            />
          ) : (
            <Fireworks
              onInit={({ conductor }) => (controller.current = conductor)}
              className={styles.canvas}
              decorateOptions={(options) => ({
                ...options,
                particleCount: 100
              })}
            />
          )}
        </div>
      )}
    </>
  )
}

export default EnvironmentRibbon
