import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { EMAIL } from 'constants/regex'
import { routes } from 'types/routes'
import { requestNewPassword, resetPassword } from 'store/auth/thunks'
import { ResetPasswordFormInputs, ResetPasswordRouteParams } from './types'
import styles from '../Auth.module.scss'
import NeedHelp from 'pages/Auth/components/NeedHelp/NeedHelp'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { Banner, Button, Form, Heading, Password, TextField } from '@boltenergy-be/design-system'
import Link from 'components/Link/Link.tsx'
import { matchPath, useParams } from 'react-router-dom'
import { IS_BETA } from 'constants/envs.ts'
import { UserTypes } from 'store/auth/types.ts'
import { determineAuthUserType } from 'pages/Auth/utils.ts'
import { getUserTypeLoginPage } from 'utils/user.ts'

const ResetPassword = () => {
  // REDUX STORE
  const { loading } = useStoreSelector((store) => store.auth)
  const dispatch = useStoreDispatch()

  // Local state
  const [error, setError] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  // React hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<ResetPasswordFormInputs>({ mode: 'onBlur' })
  const watchPassword = watch('password')

  // i18n
  const { t } = useTranslation(['auth', 'common', 'validation'])

  // React router
  const navigate = useNavigate()
  const { token } = useParams<ResetPasswordRouteParams>()
  const { pathname } = useLocation()

  // Constants
  const isActivate =
    matchPath(routes.ACTIVATE, pathname) || matchPath(routes.ACTIVATE_SALES, pathname) || matchPath(routes.ACTIVATE_SUPER_USER, pathname)
  const isRequestNewPassword = [
    routes.REQUEST_NEW_PASSWORD,
    routes.REQUEST_NEW_PASSWORD_SALES,
    routes.REQUEST_NEW_PASSWORD_SUPER_USER
  ].includes(pathname as routes)
  const translationKey = isActivate ? 'activate' : isRequestNewPassword ? 'requestNewPassword' : 'resetPassword'
  const userType = determineAuthUserType(pathname)

  // Redirect to login if in beta (reset password page inaccessible on beta)
  useEffect(() => {
    if (IS_BETA) {
      navigate(routes.LOGIN)
    }
  }, [])

  /**
   * Handles the form submit after validation
   *
   * @param {ResetPasswordFormInputs} data
   */
  const onSubmit = async (data: ResetPasswordFormInputs) => {
    setError(false)
    setSuccess(false)

    let dispatchResult

    // Check if send reset password mail page...
    if (isRequestNewPassword) {
      const { email } = data

      dispatchResult = await dispatch(requestNewPassword({ email: email?.toLowerCase() ?? '', userType }))
    }

    // ...or the reset password page itself
    else {
      const { password } = data

      dispatchResult = await dispatch(
        resetPassword({
          token: token ?? '',
          password: password ?? '',
          userType
        })
      )
    }

    if (dispatchResult.meta.requestStatus === 'fulfilled') {
      setSuccess(true)

      if (!isRequestNewPassword) {
        navigate(getUserTypeLoginPage(userType))
      }
    } else {
      setError(true)
    }
  }

  return (
    <>
      <div className={styles['auth-body']}>
        <header className={styles.header}>
          <Heading as="h1" variant="h3">
            {t(`titles.${translationKey}`)}
          </Heading>
          <p className={styles.description}>{t(`descriptions.${translationKey}`)}</p>
        </header>

        <Form onSubmit={handleSubmit(onSubmit)}>
          {isRequestNewPassword ? (
            <TextField
              id="email"
              type="email"
              autoComplete="off"
              // Disable lastpass autofill
              data-lpignore="true"
              label={t('fields.email')}
              {...register('email', {
                required: t('validation:required'),
                pattern: { value: EMAIL, message: t('validation:invalid.email') }
              })}
              error={errors?.email?.message}
            />
          ) : (
            <>
              <Password
                id="password"
                label={t('fields.password')}
                autoComplete="new-password"
                {...register('password', {
                  required: t('validation:required'),
                  minLength: { value: 8, message: t('validation:invalid.passwordMinimum', { min: 8 }) }
                })}
                error={errors?.password?.message}
              />

              <Password
                id="confirmPassword"
                autoComplete="new-password"
                label={t('fields.confirmPassword')}
                {...register('confirmPassword', {
                  required: t('validation:required'),
                  validate: (value) => value === watchPassword || t('validation:invalid.passwordConfirm')
                })}
                error={errors?.confirmPassword?.message}
              />
            </>
          )}

          <Button isFullwidth loading={loading} disabled={success}>
            {t(`buttons.${translationKey}`)}
          </Button>
        </Form>

        {!loading && (
          <>
            {success && (
              <Banner className={styles.banner} type="positive">
                {t('messages.successEmail')}
              </Banner>
            )}

            {error && (
              <Banner className={styles.banner} type="blocking">
                {t('common:error')}
              </Banner>
            )}
          </>
        )}

        <ul className={styles['links-list']}>
          <li>
            <Link
              size="medium"
              variant="secondary"
              href={
                userType === UserTypes.SALES
                  ? routes.LOGIN_SALES
                  : userType === UserTypes.SUPER_USER
                    ? routes.LOGIN_SUPER_USER
                    : routes.LOGIN
              }
              className={styles.return}
            >
              {t('buttons.backToLogin')}
            </Link>
          </li>
        </ul>
      </div>

      <footer className={styles['auth-footer']}>
        <NeedHelp />
      </footer>
    </>
  )
}

export default ResetPassword
