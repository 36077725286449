import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LoginFormFieldsType } from './types'
import { EMAIL } from 'constants/regex'
import { login } from 'store/auth/thunks'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { routes } from 'types/routes'
import styles from '../Auth.module.scss'
import { Banner, Button, Form, Heading, TextField, Tag, Password } from '@boltenergy-be/design-system'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { checkIsDown } from 'utils/app.ts'
import Link from 'components/Link/Link'
import { getUserTypeHomepage } from 'utils/user.ts'
import parse from 'html-react-parser'
import { IS_BETA } from 'constants/envs.ts'
import { determineAuthUserType } from 'pages/Auth/utils.ts'
import { UserTypes } from 'store/auth/types.ts'

const Login = () => {
  // REDUX STORE
  const { error, loading } = useStoreSelector((store) => store.auth)
  const dispatch = useStoreDispatch()

  // React hook form
  const {
    handleSubmit,
    formState: { errors },
    register
  } = useForm<LoginFormFieldsType>({ mode: 'onBlur' })

  // i18n
  const { t } = useTranslation(['auth', 'validation'])

  // React router
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // Constants
  const userType = determineAuthUserType(pathname)
  const { isDown, message } = checkIsDown(userType)

  /**
   * Changes the page title
   */
  useEffect(() => {
    if (userType === UserTypes.SALES) {
      document.title = 'Bolt Sales Portal'
    }
  }, [userType])

  /**
   * Handles the submit after the validation
   *
   * @param {LoginFormFieldsType} data
   */
  const onSubmit = async (data: LoginFormFieldsType) => {
    const { email, password } = data

    const result = await dispatch(login({ email: email.toLowerCase(), password, userType }))

    if (result.meta.requestStatus === 'fulfilled') {
      navigate(getUserTypeHomepage(userType))
    }
  }

  return (
    <>
      <div className={styles['auth-body']}>
        <header className={styles.header}>
          <div className={styles.heading}>
            <Heading as="h1" variant="h3">
              {t('titles.login')}
            </Heading>
            {userType !== UserTypes.CUSTOMER && <Tag color="orange">{t(`tags.${userType}`)}</Tag>}
          </div>
          {userType !== UserTypes.SUPER_USER && <p className={styles.description}>{t(`descriptions.login.${userType}`)}</p>}
        </header>

        {isDown ? (
          <Banner type="informative" className={styles.maintenance}>
            <p>{message}</p>
          </Banner>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              id="email"
              type="email"
              label={t('fields.email')}
              {...register('email', {
                required: t('validation:required'),
                pattern: { value: EMAIL, message: t('validation:invalid.email') }
              })}
              error={errors?.email?.message}
            />

            <Password
              id="password"
              label={t('fields.password')}
              labelComponentSlot={
                !IS_BETA && (
                  <Link
                    variant="primary"
                    href={
                      userType === UserTypes.SALES
                        ? routes.REQUEST_NEW_PASSWORD_SALES
                        : userType === UserTypes.SUPER_USER
                          ? routes.REQUEST_NEW_PASSWORD_SUPER_USER
                          : routes.REQUEST_NEW_PASSWORD
                    }
                  >
                    {t('buttons.forgotPassword')}
                  </Link>
                )
              }
              {...register('password', { required: t('validation:required') })}
              error={errors?.password?.message}
            />

            <Button isFullwidth loading={loading}>
              {t('buttons.login')}
            </Button>
          </Form>
        )}

        {error && (
          <Banner className={styles.banner} type="blocking">
            {t('messages.loginError')}
          </Banner>
        )}
      </div>

      {userType === UserTypes.CUSTOMER && !IS_BETA && (
        <footer className={styles['auth-footer']}>
          <p>{parse(t('subSections.notRegistered.text'))}</p>
          <Link className={styles['auth-link']} href={routes.REGISTER} variant="secondary">
            {t('subSections.notRegistered.button')}
          </Link>
        </footer>
      )}
    </>
  )
}

export default Login
