import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { routes } from 'types/routes'
import styles from './App.module.scss'
import { UserTypes } from 'store/auth/types'
import mixpanel from 'mixpanel-browser'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import PageLoading from 'components/PageLoading/PageLoading.tsx'
import EnergyTransitionDocumentContextProvider from 'store/react-context/energy-transition-document/EnergyTransitionDocumentContextProvider.tsx'
import { logout } from 'utils/app.ts'
import { setUserType } from 'utils/userType.ts'
import { fetchContactData, getUserTypeLoginPage } from 'utils/user.ts'
import MainNavigation from 'layouts/components/navigation/MainNavigation.tsx'
import classNames from 'classnames'
import useGetRouteLayout from 'hooks/useGetRouteLayout.ts'
import FeedbackWidget from 'components/FeedbackWidget/FeedbackWidget.tsx'
import useOpenFeedbackWidget from 'hooks/useOpenFeedbackWidget.ts'
import { WidgetSlug } from 'types/feedbackWidget.ts'
import { IS_BETA } from 'constants/envs.ts'
import { isAuthenticated } from 'utils/localStorageService.ts'
import { GetContactSuccessPayload } from 'store/contact/thunks/types.ts'
import { deleteRedirect } from 'store/app/slice.ts'
import Bugsnag from '@bugsnag/js'
import { UrlSearchParamsKeys } from 'store/app/types.ts'
import request, { getAxiosRequestConfig } from 'utils/request.ts'
import { Methods } from 'types/request.ts'
import { store } from 'store/index.ts'
import { Layouts } from 'layouts/types.ts'
import { useGetActiveReferralActionQuery } from 'store/queries/cms-api'
import useWindowSize from 'hooks/useWindowSize.tsx'
import { BREAKPOINTS } from '@boltenergy-be/design-system/dist/constants/breakpoints'

const App = () => {
  // Redux store
  const { redirect, urlSearchParams, language } = useStoreSelector((store) => store.app)
  const { error } = useStoreSelector((store) => store.contact)
  const { userType } = useStoreSelector((store) => store.auth)
  const dispatch = useStoreDispatch()

  // Referral action
  const { data: activeAction } = useGetActiveReferralActionQuery({ language })

  const { isSmaller } = useWindowSize(BREAKPOINTS.desktop)

  // Feedback widget
  const showFixedFeedbackWidget = !error && userType === UserTypes.CUSTOMER && (IS_BETA || !isSmaller)
  const { feedbackWidgetData, openFeedbackWidget } = useOpenFeedbackWidget({
    slug: WidgetSlug.BETA_NEW_PORTAL,
    skip: !showFixedFeedbackWidget
  })

  // React Router
  const { pathname } = useLocation()
  const navigate = useNavigate()

  // Auth constants
  const authenticatedUserTypes = isAuthenticated()

  // Local State
  const [isLoading, setIsLoading] = useState(true)

  // Constants
  const { layout } = useGetRouteLayout()
  const isEditingLayout = layout === Layouts.EDIT_LAYOUT
  const hideNavigation =
    [routes.ACCOUNT_SELECTION, routes.CUSTOMER_EMAIL, routes.EVENT_LOG, routes.CONTRACTS].some((route) => pathname.includes(route)) ||
    isEditingLayout
  const isContractsFlow = pathname.includes(routes.CONTRACTS)
  const hideContent = isLoading || (userType !== UserTypes.SUPER_USER && error)
  const hasFixedFeedbackWidget = showFixedFeedbackWidget && feedbackWidgetData

  // Define mixpanel super property
  useEffect(() => {
    mixpanel.register({
      userType,
      beta: IS_BETA
    })
  }, [userType])

  /**
   * Redirect to the sales portal or logout if the user is not a sales user
   */
  useEffect(() => {
    if (!authenticatedUserTypes[UserTypes.CUSTOMER] && !authenticatedUserTypes[UserTypes.SUPER_USER]) {
      logout(() => navigate(getUserTypeLoginPage(userType)))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the logout with correct redirect
   */
  const handleLogout = () =>
    logout(() => {
      setUserType(null)
      navigate(getUserTypeLoginPage(userType))
    })

  /**
   * Navigate based on contact data
   * @param {GetContactSuccessPayload | undefined} getContactData
   */
  const navigateBasedOnContactData = (getContactData: GetContactSuccessPayload | undefined) => {
    // Add user data to Bugsnag
    const contact = getContactData?.normalizedStoreData?.contact
    if (contact) {
      const { email, sfId } = contact
      Bugsnag.setUser(sfId, email)
    }

    if (
      getContactData?.selected &&
      getContactData.normalizedStoreData.accounts &&
      getContactData.selected.account in getContactData.normalizedStoreData.accounts
    ) {
      // Redirect if it is present in AppStore
      if (redirect) {
        navigate(redirect)
        // Clear redirect from AppStore
        dispatch(deleteRedirect())
      } else {
        navigate(routes.OVERVIEW)
      }
    } else {
      // Multiple active contracts: redirect to choose address page
      navigate(routes.ACCOUNT_SELECTION)
    }

    setIsLoading(false)
  }

  /**
   * Initiate fetch contact data & navigate to correct page
   */
  const initiateContactData = async () => {
    const contactData = await fetchContactData(UserTypes.CUSTOMER)
    if (contactData) {
      navigateBasedOnContactData(contactData)
    }

    setIsLoading(false)
  }

  /**
   * Fetches the contact data for the contact id in the urlSearchParams store entry & navigate to correct page
   * @returns {Promise<GetContactSuccessPayload | undefined>}
   */
  const initiateContactDataForContactId = async () => {
    const contactId = urlSearchParams[UrlSearchParamsKeys.CONTACT_ID]
    let contactData

    if (contactId) {
      const { success, data } = await request(getAxiosRequestConfig(Methods.GET, `/contact/${contactId}/email`))

      if (success) {
        const { email } = data
        contactData = await fetchContactData(UserTypes.SUPER_USER, email)
      }
    }

    navigateBasedOnContactData(contactData)
  }

  /**
   * Fetch contact data
   */
  useEffect(() => {
    const { urlSearchParams } = store.getState().app

    if (!userType && (authenticatedUserTypes[UserTypes.CUSTOMER] || authenticatedUserTypes[UserTypes.SUPER_USER])) {
      setUserType(authenticatedUserTypes[UserTypes.SUPER_USER] ? UserTypes.SUPER_USER : UserTypes.CUSTOMER)
    }

    if (userType && userType !== UserTypes.SALES) {
      if (userType === UserTypes.SUPER_USER) {
        // Fetch user data by contact id if necessary
        if (urlSearchParams[UrlSearchParamsKeys.CONTACT_ID]) initiateContactDataForContactId()
        else setIsLoading(false)
        navigate(routes.CUSTOMER_EMAIL)
      } else {
        // Fetch 'normal' user data
        initiateContactData()
      }
    }
  }, [userType])

  // Toggle overflo
  useEffect(() => {
    if (pathname === routes.OVERVIEW) {
      document.body.classList.add('overview-page')
      document.body.scrollTo(0, 0)
    } else {
      document.body.classList.remove('overview-page')
    }
  }, [pathname])

  return isContractsFlow ? (
    // Contracts layout is inside the pages
    <EnergyTransitionDocumentContextProvider>
      {hideContent ? <PageLoading {...{ isLoading }} /> : <Outlet />}
    </EnergyTransitionDocumentContextProvider>
  ) : (
    <div
      className={classNames(styles['app-layout'], {
        [styles['has-navigation']]: !hideNavigation,
        [styles['has-feedback']]: hasFixedFeedbackWidget
      })}
    >
      {!hideNavigation && <MainNavigation handleLogout={handleLogout} isSales={userType === UserTypes.SALES} withAction={!!activeAction} />}

      <main className={classNames(styles.main, { [styles.wide]: isEditingLayout })}>
        {hideContent ? <PageLoading {...{ isLoading }} /> : <Outlet />}
      </main>

      {hasFixedFeedbackWidget && (
        <FeedbackWidget
          className={styles.feedback}
          data={{ page: pathname }}
          id={feedbackWidgetData._id}
          openFeedbackWidget={openFeedbackWidget}
          showTrigger={showFixedFeedbackWidget}
          isBetaFeedback={showFixedFeedbackWidget}
        />
      )}
    </div>
  )
}

export default App
