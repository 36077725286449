// Common
export enum CommonTrackingEvents {
  CLICK_MOVE_BANNER = 'click-move-banner',
  CLICK_NAVIGATION_ITEM = 'click-navigation-item',
  SWITCH_SUBNAV_TAB = 'switch-subnav-tab',
  CLICK_ACCOUNT_NAME = 'click-account-name',
  LOGOUT = 'logout',
  TOGGLE_LANGUAGE = 'toggle-language',
  OPEN_FEEDBACK_WIDGET = 'open-feedback-widget'
}

export enum CommonTrackingParams {
  ROUTE = 'route'
}

export enum NavigationTypes {
  MAIN = 'main',
  MORE = 'more'
}

// Overview
export enum OverviewEvents {
  CLICK_OVERVIEW_CARD = 'click-overview-card',
  SCROLLED_WITH_SNAPPING = 'scrolled-with-snapping'
}

// Consumption Events
export enum ConsumptionEvents {
  PLAN_CONSUMPTION_TOGGLED = 'consumption-plan_consumption_toggled',
  CONSUMPTION_NAVIGATION_ITEM_VIEWED = 'consumption-consumption_navigation_item_viewed',
  METER_READING_DETAILS_OPENED = 'meter_reading_details_opened',
  CONFIRM_ADD_METER_READING = 'confirm_add_meter_reading',
  DOWNLOADED_CSV = 'downloaded_csv'
}

export enum ConsumptionTrackingParams {
  VIEW = 'view',
  FUEL_TYPE = 'fuelType'
}

// Smart Meter Options
export enum SmartMeterOptionEvents {
  EDIT_OPTIONS_CONFIRMED = 'smart_meter-edit_options_confirmed',
  EDIT_OPTIONS_CONFIRMATION_OPENED = 'smart_meter-edit_options_confirmation_opened',
  EDIT_OPTIONS_CANCELED = 'smart_meter-edit_options_canceled',
  EDIT_OPTIONS_STARTED = 'smart_meter-edit_options_started',
  EDIT_OPTION_TOGGLED = 'smart_meter-edit_option_toggled'
}

export enum SmartMeterOptionTrackingParams {
  OPTION = 'option',
  NEW_VALUE = 'newValue',
  SAME_AS_ORIGINAL = 'sameAsOriginal'
}

// Contract flows
export enum ContractFlowTrackingTypes {
  TERMINATE = 'terminate',
  ADD = 'add'
}

export enum ContractFlowEvents {
  OVERVIEW = 'open_contract_actions_overview',
  START = 'start_contract_flow',
  CONFIRM = 'confirm_contract_flow',
  NEXT_STEP = 'next_step_contract_flow',
  PREVIOUS_STEP = 'previous_step_contract_flow',
  ATTEMPT_ABORT = 'attempt_abort_contract_flow'
}

export enum ContractFlowStartPoints {
  YOUR_CONTRACT = 'your_contract',
  FINISH_MOVE = 'finish_move',
  CONTRACT_OVERVIEW = 'contract_overview',
  ADDRESSES = 'address_overview',
  ADDRESS_DETAIL = 'address_detail'
}

// Invoices
export enum InvoiceEvents {
  INVOICE_TAB_VIEWED = 'invoice_tab_viewed',
  OVERVIEW = 'open_invoice_overview',
  DOWNLOAD_PDF = 'download_invoice_pdf',
  PAY_INVOICE = 'pay_invoice',
  ALL_TRANSACTIONS = 'all_transactions'
}

// Billing
export enum BillingEvents {
  OPEN_BILLING_CYCLE_DETAIL = 'open_billing_cycle_detail',
  GO_TO_ADD_METER_READING = 'go_to_add_meter_reading',
  GO_TO_CHANGE_INSTALMENT = 'go_to_change_instalment',
  CONFIRM_CHANGE_INSTALMENT = 'confirm_change_instalment',
  TOGGLE_BILLING_CYCLE_VIEW = 'toggle_billing_cycle_view'
}

// Referral
export enum ReferralEvents {
  GO_TO_REFERRAL_PAGE = 'go_to_referral_page',
  COPY = 'copy_referral_link',
  SHARE = 'share_referral_link',
  OPEN_ACTION_INFO = 'open_action_info'
}

// Energy Overview
export enum EnergyOverviewEvents {
  GO_TO_SLIDE = 'go_to_slide'
}

// Producer
export enum ProducerEvents {
  PAGE_OPENED = 'producer_page_opened'
}

// Addresses
export enum AddressEvents {
  OPEN_ADDRESS_DETAIL = 'open_address_detail',
  DOWNLOAD_TARIFF_CARD = 'download_tariff_card',
  EXTEND_FIXED_TARIFF = 'extend_fixed_tariff',
  VIEW_RENEWAL = 'view_renewal'
}

// User
export enum UserEvents {
  GO_TO_CONTACT = 'go_to_contact',
  GO_TO_EDIT_PAYMENT_INFO = 'go_to_edit_payment_info',
  GO_TO_EDIT_CONTACT_INFO = 'go_to_edit_contact_info',
  GO_TO_EDIT_PERSONAL_INFO = 'go_to_edit_personal_info',
  SUBMIT_EDIT_PAYMENT_INFO = 'submit_edit_payment_info',
  SUBMIT_EDIT_CONTACT_INFO = 'submit_edit_contact_info',
  SUBMIT_EDIT_PERSONAL_INFO = 'submit_edit_personal_info'
}

// Support
export enum SupportEvents {
  SUBMIT_CONTACT_FORM = 'submit_contact_form',
  OPEN_FAQ_DRAWER = 'open-faq-button'
}
