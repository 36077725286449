import addresses from './addresses.json'
import auth from './auth.json'
import billing from './billing.json'
import common from './common.json'
import consumption from './consumption.json'
import contracts from './contracts.json'
import energyOverview from './energyOverview.json'
import feedbackWidget from './feedbackWidget.json'
import guaranteeOfOrigin from './guaranteeOfOrigin.json'
import home from './home.json'
import invoices from './invoices.json'
import marketPrices from './marketPrices.json'
import move from './move.json'
import overview from './overview.json'
import producer from './producer.json'
import referral from './referral.json'
import sales from './sales.json'
import support from './support.json'
import user from './user.json'
import validation from './validation.json'

const resources = {
  addresses,
  auth,
  billing,
  common,
  consumption,
  contracts,
  energyOverview,
  feedbackWidget,
  guaranteeOfOrigin,
  home,
  invoices,
  marketPrices,
  move,
  overview,
  producer,
  referral,
  sales,
  support,
  user,
  validation
} as const

export default resources
