import { Button, Form, Heading, popSuccessToast, TextField, Banner } from '@boltenergy-be/design-system'
import { useTranslation } from 'react-i18next'
import { selectContact, selectCurrentAccount, selectCurrentContracts } from 'store/contact/selectors'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import styles from './PersonalForm.module.scss'
import Phone from 'components/FormFields/Phone/Phone'
import { FormProvider, useForm } from 'react-hook-form'
import { PersonalFormInputs } from '../types'
import DateFormField from 'components/FormFields/DateFormField/DateFormField'
import mixpanel from 'mixpanel-browser'
import { updateAccount, updateContact } from 'store/contact/thunks'
import { UserEvents } from 'types/tracking'
import { dateStringToObject } from 'utils/addContract'
import dayjs from 'dayjs'
import { routes } from 'types/routes'
import Link from 'components/Link/Link'
import { useNavigate } from 'react-router-dom'

const PersonalForm = () => {
  //i18n
  const { t } = useTranslation(['user', 'common', 'contracts'])

  // Router
  const navigate = useNavigate()

  // Redux
  const dispatch = useStoreDispatch()
  const contactStore = useStoreSelector((store) => store.contact)
  const contact = selectContact(contactStore)
  const { loading } = contactStore
  const account = selectCurrentAccount(contactStore)
  const { serviceContracts } = selectCurrentContracts(contactStore)
  const { contactSfId, sfId } = account

  // React hook form

  const hookForm = useForm<PersonalFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      phone: contact.phone || '',
      dateOfBirth: account.birthDate ? dateStringToObject(account.birthDate) : undefined
    }
  })
  const {
    handleSubmit,
    formState: { errors, dirtyFields }
  } = hookForm

  /**
   * Handles the form submission after validation by React Hook Form
   *
   * @param {PersonalFormInputs} data
   */
  const onSubmit = async (data: PersonalFormInputs) => {
    // Update the account (only map the fields that have changed since it's a PATCH request)
    let resAccount
    let resContact

    //update account fields
    if (dirtyFields.dateOfBirth) {
      resAccount = await dispatch(
        updateAccount({
          accountId: sfId,
          contactId: contactSfId,
          account: {
            birthDate: dayjs(`${data.dateOfBirth.year}-${data.dateOfBirth.month}-${data.dateOfBirth.day}`).format('YYYY-MM-DD')
          }
        })
      )
    }

    //update contact fields
    if (dirtyFields.phone) {
      resContact = await dispatch(
        updateContact({
          contactId: contactSfId,
          contact: {
            phone: data.phone
          },
          deliveryAddress: serviceContracts.electricity.deliveryPoint.address
        })
      )
    }

    if (resAccount?.meta.requestStatus === 'fulfilled' || resContact?.meta.requestStatus === 'fulfilled') {
      navigate(routes.USER_PERSONAL)
      popSuccessToast(t('common:formFeedback.saved'))
    }

    mixpanel.track(UserEvents.SUBMIT_EDIT_PERSONAL_INFO)
  }

  return (
    <Form className={styles['personal-form']} onSubmit={handleSubmit(onSubmit)}>
      <Banner type="informative">
        {t('personal.form.info', { url: routes.SUPPORT_CONTACT })}{' '}
        <Link href={routes.SUPPORT_CONTACT} onClick={() => mixpanel.track(UserEvents.GO_TO_CONTACT)} variant="secondary">
          {t('personal.form.contact')}
        </Link>
      </Banner>

      <Heading as="h2" variant="h5" className="mt-500">
        {t('common:personal')}
      </Heading>

      {/*Customer Number*/}
      <TextField
        id="customerNumber"
        label={t('personal.form.clientNbr')}
        type="number"
        name="customerNumber"
        defaultValue={account.customerNumber}
        disabled
        grid="full"
      />

      {/*First Name*/}
      <TextField
        id="firstName"
        label={t('personal.form.firstName')}
        type="text"
        name="firstName"
        defaultValue={contact.firstName}
        disabled
        grid="full"
      />

      {/*Last Name*/}
      <TextField
        id="lastName"
        label={t('personal.form.lastName')}
        type="text"
        name="lastName"
        defaultValue={contact.lastName}
        disabled
        grid="full"
      />

      {/*Email*/}
      <TextField id="email" label={t('personal.form.email')} type="email" name="email" defaultValue={contact.email} disabled grid="full" />

      {/*Phone*/}
      <Phone label={t('personal.form.phone')} hookFormIdentifier="phone" {...{ hookForm }} />

      {/*Birth Date*/}
      <FormProvider {...hookForm}>
        <DateFormField label={t('personal.form.birthDate.title')} checkDateOfBirth />
      </FormProvider>

      {!!account.company && (
        <>
          <Heading as="h2" variant="h5">
            {t('contracts:add.steps.customerData.sections.company')}
          </Heading>

          {/*Company Name*/}
          <TextField
            id="companyName"
            label={t('personal.form.company.name')}
            type="text"
            name="companyName"
            defaultValue={account.company.name}
            disabled
            grid="full"
          />

          {/*Legal Form*/}
          <TextField
            id="companyLegalForm"
            label={t('personal.form.company.legalForm')}
            type="text"
            name="companyLegalForm"
            defaultValue={account.company.legalForm}
            disabled
            grid="full"
          />

          {/*Enterprise Number*/}
          <TextField
            id="companyEnterpriseNumber"
            label={t('personal.form.company.enterpriseNumber')}
            type="text"
            name="companyEnterpriseNumber"
            defaultValue={account.company.enterpriseNumber}
            disabled
            grid="full"
          />
        </>
      )}

      <Button isFullwidth type="submit" disabled={Object.keys(errors).length > 0} loading={loading}>
        {t('save', { ns: 'common' })}
      </Button>
    </Form>
  )
}

export default PersonalForm
