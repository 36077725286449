import { ContactStore, NormalizedEntities } from 'store/contact/types.ts'
import { DeepPartial } from 'types/utility.ts'
import { ServiceContractElectricity, ServiceContractGas, BillingContract } from 'types/contracts.ts'
import { Contact } from 'types/contacts.ts'
import { ApiResponseCodes, ContactsResponseCodes, ContractResponseCodes } from 'types/errorCodes.ts'
import { Address } from 'types/general'

export type RejectValue = {
  rejectValue: ApiResponseCodes | ContactsResponseCodes | ContractResponseCodes | ThunkDefaultErrorMsg | string
}

// GET CONTACT

export type GetContactPayload = {
  email: string
}

export type GetContactSuccessPayload = {
  normalizedStoreData: NormalizedEntities
  selected?: ContactStore['selected']
  ignoredBillingContracts: string[]
}

export type GetContactErrorPayload = {
  error: ContactStore['error']
  errorData?: ContactStore['errorData']
}

// UPDATE BILLING CONTRACT

export type UpdateBillingPayload = {
  billingContractId: string
  updateBillingContractData: DeepPartial<BillingContract>
}

export type UpdateBillingSuccessPayload = {
  billingContract: Pick<BillingContract, 'contractNumber' | 'customerNumber' | 'deliveryMode'>
}

// UPDATE INSTALMENTS

export type UpdateInstalmentsPayload = {
  billingContractId: string
  customerNumber: string
  electricity: ServiceContractElectricity
  gas?: ServiceContractGas
}

// UPDATE CONTACT

export type UpdateContactPayload = {
  contactId: string
  contact: DeepPartial<Contact>
  deliveryAddress?: Address
}

export type UpdateContactResponse = {
  contact: Contact
}

export enum ThunkDefaultErrorMsg {
  UPDATE_CONTACT = 'updateContact',
  UPDATE_ACCOUNT = 'updateAccount',
  UPDATE_INSTALMENT = 'updateInstalment',
  UPDATE_BILLING_CONTRACT = 'updateBillingContract',
  GET_MY_CONTACT = 'getMyContact',
  GET_CONTACT_BY_EMAIL = 'getContactByEmail'
}
