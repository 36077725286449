import classNames from 'classnames'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styles from './PreviousInhabitantForm.module.scss'
import { useState } from 'react'
import { getTown } from 'api/addContract'
import { Town } from 'api/types'
import { ClientType } from 'types/types'
import { isValidPostalCode } from 'utils/addContract'
import { MeterDataFormInputs } from '../../types'
import { Heading, Select, TextField } from '@boltenergy-be/design-system'

const PreviousInhabitantForm = () => {
  // i18n
  const { t } = useTranslation('contracts')

  // React Hook Form
  const { register, watch, setValue } = useFormContext<MeterDataFormInputs>()
  const watchTownName = watch('previousInhabitant.address.townName')

  // Local state
  const [selectableTowns, setSelectableTowns] = useState<Town[]>([])

  /**
   * Handles the town select change & sets the town code
   *
   * @param {string} selectedTown
   */
  const handleTownSelectChange = (selectedTown: string) => {
    const town = selectableTowns.find((town) => town.townName === selectedTown)
    const townCode = town?.townCode ?? 0
    const townName = town?.townName ?? ''

    setValue('previousInhabitant.address.townCode', townCode as never, { shouldDirty: true, shouldValidate: true })
    setValue('previousInhabitant.address.townName', townName as never, { shouldDirty: true, shouldValidate: true })
  }

  /**
   * Fetches the town names based on the given postalCode
   *
   * @param {number} postalCode
   */
  const validatePostalCodeAndFetchTowns = async (postalCode: number) => {
    if (isValidPostalCode(postalCode)) {
      const response = await getTown(postalCode)

      if (response !== null) {
        const { towns } = response
        setSelectableTowns(towns)

        if (!towns.some((town) => town.townName === watchTownName)) {
          setValue('previousInhabitant.address.townName', towns[0].townName as never, {
            shouldDirty: true,
            shouldValidate: true
          })
          setValue('previousInhabitant.address.townCode', towns[0].townCode as never, {
            shouldDirty: true,
            shouldValidate: true
          })
        }

        return true
      }
    }

    return t('invalid.postalCode', { ns: 'validation' })
  }

  return (
    <section className={classNames(styles['previous-inhabitant-form'], 'grid-col-full')}>
      <header>
        <Heading as="h1" variant="h4" className={classNames('mb-400')}>
          {t('add.steps.meterDetails.form.previousInhabitant.title')}
        </Heading>
        <p>{t('add.steps.meterDetails.form.previousInhabitant.description')}</p>
      </header>

      <TextField
        id="firstName"
        label={t('add.steps.customerData.fields.firstName')}
        {...register('previousInhabitant.firstName')}
        grid="full"
      />

      <TextField
        id="lastName"
        label={t('add.steps.customerData.fields.lastName')}
        {...register('previousInhabitant.lastName')}
        grid="full"
      />

      <TextField
        grid="full"
        id="streetName"
        label={t('add.steps.customerData.fields.correspondenceAddress.streetName')}
        {...register('previousInhabitant.address.streetName')}
      />

      <TextField
        grid="span 1"
        id="streetNumber"
        label={t('add.steps.customerData.fields.correspondenceAddress.streetNumber')}
        {...register('previousInhabitant.address.streetNumber')}
      />

      <TextField
        grid="span 1"
        id="streetBox"
        label={t('add.steps.customerData.fields.correspondenceAddress.streetBox')}
        {...register('previousInhabitant.address.streetBox')}
      />

      <TextField
        grid="full"
        id="postalCode"
        label={t('add.steps.customerData.fields.correspondenceAddress.postalCode')}
        {...register('previousInhabitant.address.postalCode', {
          validate: (val) => validatePostalCodeAndFetchTowns(parseInt(val as string))
        })}
        type="number"
        onChange={(e) => {
          const value = e.target.value
          setValue('previousInhabitant.address.postalCode', value as never, { shouldDirty: true, shouldValidate: true })
          validatePostalCodeAndFetchTowns(parseInt(value))
        }}
      />

      <Select
        grid="full"
        id="townName"
        label={t('add.steps.customerData.fields.correspondenceAddress.townName')}
        {...register('previousInhabitant.address.townName')}
        onChange={(e) => handleTownSelectChange(e.target.value)}
        value={watchTownName}
      >
        <option disabled>
          {watch('previousInhabitant.address.postalCode') === '' ? 'text' : t('defaultPlaceholders.select', { ns: 'common' })}
        </option>
        {selectableTowns.map((town) => {
          return (
            <option key={town.townCode} value={town.townName}>
              {town.townName}
            </option>
          )
        })}
      </Select>

      <TextField grid="full" id="supplier" label={t('supplier', { ns: 'common' })} {...register('previousInhabitant.supplier')} />

      <Select
        grid="full"
        id="clientType"
        label={t('details.customer.customerType', { ns: 'sales' })}
        {...register('previousInhabitant.clientType')}
      >
        <option value={ClientType.RESIDENTIAL}>{t('details.customer.residential', { ns: 'sales' })}</option>
        <option value={ClientType.PROFESSIONAL}>{t('details.customer.professional', { ns: 'sales' })}</option>
      </Select>
    </section>
  )
}

export default PreviousInhabitantForm
