import { useState } from 'react'
import { ChurnReasons, TerminateContractRequest, TerminateStepProps, TerminateSteps } from '../../types'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import parse from 'html-react-parser'
import mixpanel from 'mixpanel-browser'
import { DATE_FORMAT } from 'constants/constants'
import { fileToBase64 } from 'utils/files'
import { getAxiosRequestConfig, newRequest } from 'utils/request'
import { Register, TimeframeCode } from 'types/types'
import { Methods, Response } from 'types/request'
import { generateContractsDataWithIndexes } from 'utils/contracts'
import styles from '../../Terminate.module.scss'
import contractsStyles from '../../../Contracts.module.scss'
import Card from 'components/Card/Card.tsx'
import Bugsnag from '@bugsnag/js'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import { log } from 'utils/logging.ts'
import { Flow } from 'types/logging.ts'
import DataBlock from 'features/contracts/add/components/DataBlock/DataBlock.tsx'
import { ContractFlowEvents, ContractFlowTrackingTypes } from 'types/tracking.ts'
import { Heading, InlineMessage } from '@boltenergy-be/design-system'
import FormButtons from 'features/contracts/components/form-buttons/FormButtons.tsx'
import { setMoveFlow, setMoveFlowState } from 'store/app/slice.ts'
import { LanguageLowercase, MoveFlows } from 'store/app/types.ts'
import { selectContact, selectCurrentContracts } from 'store/contact/selectors.ts'
import { IS_BETA } from 'constants/envs.ts'

const Overview = ({ setCurrentStep, terminateData }: TerminateStepProps) => {
  // REDUX STORE
  const contactsStore = useStoreSelector((store) => store.contact)
  const { move } = useStoreSelector((store) => store.app)
  const dispatch = useStoreDispatch()

  // Contracts
  const billingContract = selectCurrentContracts(contactsStore)
  const { electricity, gas } = billingContract.serviceContracts
  const contact = selectContact(contactsStore)

  // Local state
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  // i18n
  const { t } = useTranslation(['contracts', 'common'])

  // Constants
  const isDateInFuture = dayjs(terminateData[TerminateSteps.METER_DETAILS].date).isAfter(dayjs())
  const cantTerminateContract =
    !terminateData[TerminateSteps.METER_DETAILS].digitalMeter &&
    !isDateInFuture &&
    typeof electricity.deliveryPoint.previousIndex === 'undefined'

  /**
   * Handles the form submit
   * Terminates the contract
   */
  const onSubmit = async () => {
    setIsLoading(true)

    if (IS_BETA) {
      log({ error: 'Cannot terminate a contract in beta', identifier: '[BETA:contracts]' })
      return
    }

    try {
      // Add user data to Bugsnag
      Bugsnag.addMetadata('user', contact)

      const requestData: TerminateContractRequest = {
        // Supplier switch default (but should never be the case). Necessary due to undefined initial value
        churnReason: terminateData[TerminateSteps.CHURN] || ChurnReasons.SUPPLIER_SWITCH,
        date: terminateData[TerminateSteps.METER_DETAILS].date,
        deliveryAddress: electricity.deliveryPoint.address,
        digitalMeter: terminateData[TerminateSteps.METER_DETAILS].digitalMeter,
        ...(terminateData[TerminateSteps.METER_DETAILS].docUpload && {
          document: (await fileToBase64(terminateData[TerminateSteps.METER_DETAILS].docUpload[0])) as string
        }),
        email: contact.email,
        language: contact.language.toLowerCase() as LanguageLowercase,
        referral: terminateData[TerminateSteps.REFERRAL],
        contracts: generateContractsDataWithIndexes(terminateData[TerminateSteps.METER_DETAILS].indexes, electricity, gas, isDateInFuture),
        moveFlow: move.flow
      }

      const { success, message }: Response = await newRequest(
        getAxiosRequestConfig(Methods.PATCH, `contracts/${billingContract.contractNumber}/terminate`, requestData)
      )

      // Add the move flow state to the store
      dispatch(
        setMoveFlowState({
          key: 'stopped',
          state: {
            address: electricity.deliveryPoint.address,
            date: terminateData[TerminateSteps.METER_DETAILS].date
          }
        })
      )

      // Change the move flow when the reason is "Move in Belgium" for extra CTA on last step
      if (move.flow !== MoveFlows.START_STOP && terminateData[TerminateSteps.CHURN] === ChurnReasons.MOVE_IN_BELGIUM) {
        dispatch(setMoveFlow(MoveFlows.STOP_START))
      }

      if (success) {
        mixpanel.track(ContractFlowEvents.CONFIRM, { flow: move.flow, type: ContractFlowTrackingTypes.TERMINATE })
        setCurrentStep(TerminateSteps.DONE)
      } else {
        log({
          error: 'ApiError',
          metaData: {
            data: {
              message,
              requestData
            }
          },
          identifier: `[${Flow.CONTRACT}:terminateContract]`
        })
        setError(true)
      }
    } catch (error) {
      log({
        error: error as Error,
        identifier: `[${Flow.CONTRACT}:terminateContractCatch]`
      })
      setError(true)
    }

    setIsLoading(false)
  }

  return (
    <div className={contractsStyles['form-card']}>
      <div className={contractsStyles['form-content']}>
        <header>
          <Card.Title>{t('terminate.overview.title')}</Card.Title>
          {!cantTerminateContract && <p className="mt-400">{t('terminate.overview.description')}</p>}
        </header>

        {cantTerminateContract ? (
          <p>{parse(t('terminate.overview.cantTerminate'))}</p>
        ) : (
          <section className={contractsStyles['overview-section']}>
            {terminateData[TerminateSteps.CHURN] && (
              <DataBlock label={t('terminate.overview.fields.churn')}>
                {t(`terminate.churn.reasons.${terminateData[TerminateSteps.CHURN]}`)}
              </DataBlock>
            )}

            <DataBlock label={t('terminate.overview.fields.date')}>
              {dayjs(terminateData[TerminateSteps.METER_DETAILS].date).format(DATE_FORMAT)}
            </DataBlock>

            <DataBlock label={t('terminate.overview.fields.document')}>
              {terminateData[TerminateSteps.METER_DETAILS].docUpload
                ? terminateData[TerminateSteps.METER_DETAILS].docUpload[0].name
                : t('fields.document.none')}
            </DataBlock>

            {terminateData[TerminateSteps.METER_DETAILS].digitalMeter || isDateInFuture ? (
              <>
                <DataBlock label={t('terminate.overview.fields.indexes')}>
                  {t(
                    `fields.meterReadings.${terminateData[TerminateSteps.METER_DETAILS].digitalMeter ? 'messageDigitalMeter' : 'messageDateInFuture'}`
                  )}
                </DataBlock>
              </>
            ) : (
              <>
                <div className={contractsStyles['overview-topics']}>
                  <Heading as="h3" variant="h6" className={styles['index-header']}>
                    {t('common:indexes.title')} {t('common:electricity').toLowerCase()}
                  </Heading>

                  {electricity.deliveryPoint.previousIndex?.registers
                    .filter((indexRegister: Register) => indexRegister.timeframeCode !== TimeframeCode.NOT_USED)
                    .map((_, index: number) => (
                      <DataBlock key={index}>
                        {(terminateData[TerminateSteps.METER_DETAILS].indexes.electricity?.[index] ?? 0).toString()}
                      </DataBlock>
                    ))}
                </div>

                {gas && (
                  <div className={contractsStyles['overview-topics']}>
                    <Heading as="h3" variant="h6" className={styles['index-header']}>
                      {t('common:indexes.title')} {t('common:gas').toLowerCase()}
                    </Heading>

                    <DataBlock>{(terminateData[TerminateSteps.METER_DETAILS].indexes.gas?.[0] ?? 0).toString()}</DataBlock>
                  </div>
                )}
              </>
            )}

            {error && <InlineMessage type="negative">{t('common:error')}</InlineMessage>}
          </section>
        )}
      </div>

      <FormButtons
        submitDisabled={error || isLoading || cantTerminateContract}
        loading={isLoading}
        currentStep={TerminateSteps.OVERVIEW}
        trackingId={ContractFlowTrackingTypes.TERMINATE}
        onSubmit={onSubmit}
      />
    </div>
  )
}

export default Overview
